<template>
    <iframe
        v-if="gifID"
        :src="`https://giphy.com/embed/${gifID}`"
        frameborder="0"
        class="giphy-embed"
        allow=""
        sandbox="allow-scripts"
        fetchpriority="low"
        loading="lazy"
        referrerpolicy="no-referrer"
        scrolling="no"
        @click.prevent.capture="onPrevent"
        @contextmenu.prevent.capture="onPrevent"
        @scroll.prevent.capture="onPrevent"
    />
</template>

<script>
const GIPHY_REGEX = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:giphy\.com)(?:\/(?:clips|gifs)\/)(?:[\w-]*?)([^-]+)$/i;

export default {
    props: {
        url: {
            type: String,
            default: '',
            required: true
        }
    },
    computed: {
        gifID() {
            return this?.url?.match(GIPHY_REGEX)?.[1];
        }
    },
    methods: {
        onPrevent(e) {
            // DO NOTHING!
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }
    }
};
</script>

<style lang="less" src="./GiphyEmbed.less" scoped></style>
