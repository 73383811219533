<template>
    <div
        class="hero-landing"
        :class="background"
    >
        <h1 class="hero-landing__title">
            <span v-if="subtitle">{{ subtitle }}</span>
            {{ title }}
        </h1>
        <GiphyEmbed
            v-if="gif"
            :url="gif"
            class="hero-landing__gif"
        />
        <div
            v-else-if="image"
            class="hero-landing__image"
        >
            <BaseVideo
                v-if="videoUrl"
                ref="video"
                :video-url="videoUrl"
                :video-url-mobile="videoUrlMobile"
                :poster="''"
                :autoplay="true"
                class="hero-landing__video"
            />
            <ImageAsset
                v-else-if="!videoUrl && imageMobile"
                class="hero-landing__image-mobile"
                :image="imageMobile"
                :max="980"
                sizes="100vw"
            />
            <ImageAsset
                v-if="!videoUrl"
                :image="image"
                sizes="100vw"
            />
        </div>
        <div class="hero-landing__inner">
            <div v-if="description" class="hero-landing__description article" v-html="description" /> <!-- eslint-disable-line -->
            <NavLink
                v-if="callToAction"
                :type="mapType(callToAction.__typename)"
                :url="mapURI(callToAction)"
                :blank="callToAction.openInNewWindow"
                class="hero-landing__cta button button--tertiary"
            >
                {{ callToAction.header }}
            </NavLink>
        </div>
    </div>
</template>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
    props: {
        subtitle: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        image: {
            type: Object,
            default() {
                return {};
            }
        },
        imageMobile: {
            type: Object,
            default() {
                return {};
            }
        },
        videoUrl: {
            type: String,
            default: ''
        },
        videoUrlMobile: {
            type: String,
            default: ''
        },
        gif: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        callToAction: {
            type: Object,
            default: () => {}
        },
        background: {
            type: String,
            default: ''
        }
    },

    head() {
        return {
            meta: [
                {
                    hid: 'theme-color', name: 'theme-color', content: this.backgroundColorHex
                }
            ]
        };
    },

    computed: {
        backgroundColorHex() {
            switch (this.background) {
            case 'background--green':
                return '#004F41';
            case 'background--red':
                return '#BA4356';
            case 'background--blue':
                return '#006789';
            default:
                return '#fff';
            }
        }
    }
};
</script>

<style lang="less" src="./Hero.less"></style>
